import { PropsWithChildren } from 'react';

const FeatureSectionTitle = (props: PropsWithChildren) => {
  return (
    <h2 className="text-base font-semibold leading-7 text-orange-500">
      {props.children}
    </h2>
  );
};
const FeatureSectionSubTitle = (props: PropsWithChildren) => {
  return (
    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
      {props.children}
    </p>
  );
};
const FeatureSectionDescription = (props: PropsWithChildren) => {
  return (
    <p className="mt-6 text-lg leading-8 text-gray-600">{props.children}</p>
  );
};
const FeatureSectionHeader = (props: PropsWithChildren) => {
  return (
    <div className="mx-auto max-w-2xl lg:text-center">{props.children}</div>
  );
};
const FeatureSectionBody = (props: PropsWithChildren) => {
  return (
    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 md:justify-items-start lg:max-w-none lg:grid-cols-3 lg:justify-items-center">
        {props.children}
      </dl>
    </div>
  );
};
const FeatureSection = (props: PropsWithChildren) => {
  return (
    <div className="mx-4 rounded-lg bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">{props.children}</div>
    </div>
  );
};
FeatureSection.Header = FeatureSectionHeader;
FeatureSection.Title = FeatureSectionTitle;
FeatureSection.SubTitle = FeatureSectionSubTitle;
FeatureSection.Description = FeatureSectionDescription;
FeatureSection.Body = FeatureSectionBody;
export default FeatureSection;
